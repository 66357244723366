import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import GridMyRecords from '../../components/GridMyRecords'
import GridSecundario from '../../components/GridSecundario'
import api from "../../Services/api";
import FormFabricantes from '../../components/FormFabricantes';
import FormMarca from '../../components/FormMarca';
import FormModelo from '../../components/FormModelo';
import FormContrato from '../../components/FormContrato';
import FormUnidade from '../../components/FormUnidade';
import { AxiosResponse } from "axios";
import Loading from '../../components/Loading';
import FormDepartment from '../../components/FormDepartment';
import FormDepreciation from '../../components/FormDepreciation';
import FormTipoCorretiva from '../../components/FormTipoCorretiva';
import FormTipoPreventiva from '../../components/FormTipoPreventiva';
import FormSubGrupoPreventiva from '../../components/FormSubGrupoPreventiva';
import FormCorrectivesubgroup from '../../components/FormCorrectivesubgroup';
import FormTaskGroup from '../../components/FormTaskGroup';
import secureLocalStorage from "react-secure-storage";
import ModalAlert from '../../components/ModalAlert/modal'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import { useNavigate } from 'react-router';
import FormTipoTarefa from '../../components/FormTipoTarefa'
import FormColaborador from '../../components/FormColaborador'

function MeusCadastros() {

  const navigate = useNavigate();

  const [exibirGrid, setExibirGrid] = useState(<></>);


  const [modalPlanejador, setModalPlanejador] = useState(<></>);
  const [qualGrid, setQualGrid] = useState(<></>)
  const [qualTitulo, setQualTitulo] = useState('')
  const [dadosFabricante, setDadosFabricante] = useState([]);
  const [carregaFabricante, setCarregaFabricante] = useState(GetFabricante);
  const [dadosContrato, setDadosContrato] = useState([]);
  const [carregaContrato, setCarregaContrato] = useState(GetContrato);
  const [dadosMarca, setDadosMarca] = useState([]);
  const [dadosModelo, setDadosModelo] = useState([]);

  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)

  const [openModal, setOpenModal] = useState(false)


  useEffect(() => {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    validatToken();

  }, []);



  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {

        secureLocalStorage.setItem('t', ret.data.data);
        secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
        secureLocalStorage.setItem('n', ret.data.decodedToken.name);
        secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
        secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


        setExibirGrid(<GridMyRecords
          th1='Descrição'
          th2='Identificador Alternativo'
          endPoint='myrecords/buscarmyrecords'
          consult='N'
          col1='0'
          col2='2'
          col3='3'
          ambiente='%'
          converter="S"
          ChamarModalCad={ChamarModalCad}
          pag='myrecords'
          buscarmyrecords='myrecords/buscarmyrecords'
          fecharModal={fecharModalToken}
        />)

        setOpenModal(false)

      }

    } catch (e: any) {

      if (e.response.data.status === 403) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
          <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)

      }
    }

  }

  function fecharModalToken() {

    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)

  }

  function ChamarModalCad(obj: any) {

    if (obj.mrc_id === 1) {
      setQualTitulo('Cadastrar Fabricante')
      setQualGrid(<><GridSecundario
        PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroFabricante}
        Iconeditar={EditarFabricante}
        iconeExcluir={FabricanteDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='manufacturer/buscarmanufacturer'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");

    }

    if (obj.mrc_id === 2) {
      setQualTitulo('Cadastrar Marca')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroMarca} Iconeditar={EditarMarca} iconeExcluir={MarcaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='brand/buscarbrand'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente=''
        pag="ok"
      /></>)
      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");
    }
    if (obj.mrc_id === 3) {
      setQualTitulo('Cadastrar Modelo')
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroModelo} Iconeditar={EditarModelo} iconeExcluir={ModeloDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='model/buscarmodel'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        pag="ok"
      /></>))
      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");
    } if (obj.mrc_id === 4) {
      setQualTitulo('Cadastrar Contrato')
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroContrato} Iconeditar={EditarContrato} iconeExcluir={ContratoDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='contract/buscarcontract'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        dbtnDelete="N"
        pag="ok"
      /></>))
      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");
    } if (obj.mrc_id === 5) {
      setQualTitulo('Cadastrar Unidade')
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroUnidade} Iconeditar={EditarUnidade} iconeExcluir={UnidadeDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='unity/buscarunity'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        dbtnDelete="N"
        pag="ok"
      /></>))
      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");
    } if (obj.mrc_id === 6) {
      setQualTitulo('Cadastrar Local')
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroDepartamento} Iconeditar={EditarDepartment} iconeExcluir={DepartamentoDeletar}
        th1='Ativo'
        th2='Descrição'
        th3='Identificador Alternativo'
        th4='Subcategoria'
        th5='Data da Inclusão'
        th6='Data Atualização'
        endPoint='local/listarlocal'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        dbtnDelete="N"
      /></>))
      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");
    } if (obj.mrc_id === 7) {
      setQualTitulo('Cadastrar Depreciação')
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroDepreciation} Iconeditar={EditarDepreciation} iconeExcluir={DepreciationDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='depreciation/buscardepreciation'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        pag="ok"
      /></>))
      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");
    }

    if (obj.mrc_id === 10) {
      setQualTitulo('Tipo Corretiva')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroTipoCorretiva}
        Iconeditar={EditarTipoCorretiva}
        iconeExcluir={tipoCorretivaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='correctivetype/buscarcorrectivetype'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        pag="ok"
        ambiente='%'
      /></>)

      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");

    }

    if (obj.mrc_id === 11) {
      setQualTitulo('Tipo Preventiva')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroTipoPreventiva}
        Iconeditar={EditarTipoPreventiva}
        iconeExcluir={tipoPreventivaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='preventivetype/buscarpreventivetype'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");

    }

    if (obj.mrc_id === 9) {
      setQualTitulo('Subgrupo Preventiva')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroSubgrupoPreventiva}
        Iconeditar={EditarSubgrupoPreventiva}
        iconeExcluir={subgrupoPreventivaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='preventivesubgroup/buscarpreventivesubgroup'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");

    }


    if (obj.mrc_id === 12) {
      setQualTitulo('Subgrupo Corretiva')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroSubgrupoCorretiva}
        Iconeditar={EditarSubgrupoCorretiva}
        iconeExcluir={subgrupoCorretivaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='correctivesubgroup/buscarcorrectivesubgroup'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");

    }


    if (obj.mrc_id === 13) {
      setQualTitulo('Grupo da Tarefa')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastrogrupotarefa}
        Iconeditar={Editargrupotarefa}
        iconeExcluir={grupotarefaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='taskgroup/buscar'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");

    }

    if (obj.mrc_id === 14) {
      setQualTitulo('Tipo de Tarefa')
      setQualGrid(<><GridSecundario 
        PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastrotipotarefa}
        Iconeditar={Editartipotarefa}
        iconeExcluir={tipotarefaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='tasktype/buscartasktype'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)
      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");

    }

    if (obj.mrc_id === 15) {
      setQualTitulo('Colaboradores')
      setQualGrid(<><GridSecundario 
        PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastrotipotarefa}
        Iconeditar={Editartipotarefa}
        iconeExcluir={tipotarefaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='collaborators/buscar'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)
      setOpenModalIncluirNovo(true)
      sessionStorage.removeItem("dadosFab");

    }


  }


  //=================================Subgrupo Preentiva=============================================
  function ChamarCadastroSubgrupoCorretiva() {
    sessionStorage.removeItem("dadosFab");
    setQualTitulo('Subgrupo Corretiva')
    setQualGrid(<><FormCorrectivesubgroup voltar={BtnVoltarSubgrupoCorretiva} /></>)
  }

  async function EditarSubgrupoCorretiva(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosFab", JSON.stringify(obj));
      setQualTitulo('Subgrupo Corretiva')
      setQualGrid(<><FormCorrectivesubgroup voltar={BtnVoltarSubgrupoCorretiva} /></>)
    }
  }

  function subgrupoCorretivaDeletar(obj: any) {

    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarSubgrupoCorretiva(obj.csg_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }


  async function deletarSubgrupoCorretiva(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("correctivesubgroup/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Subgrupo Corretiva')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastroSubgrupoCorretiva}
          Iconeditar={EditarSubgrupoCorretiva}
          iconeExcluir={subgrupoCorretivaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='correctivesubgroup/buscarcorrectivesubgroup'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)

      }
    } catch (e: any) {
      setQualTitulo('Subgrupo Corretiva')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroSubgrupoCorretiva}
        Iconeditar={EditarSubgrupoCorretiva}
        iconeExcluir={subgrupoCorretivaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='correctivesubgroup/buscarcorrectivesubgroup'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

    }
  }

  function BtnVoltarSubgrupoCorretiva() {
    setQualTitulo('Subgrupo Corretiva')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
      Iconeadd={ChamarCadastroSubgrupoCorretiva}
      Iconeditar={EditarSubgrupoCorretiva}
      iconeExcluir={subgrupoCorretivaDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='correctivesubgroup/buscarcorrectivesubgroup'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      pag="ok"
    /></>)
  }

  //=================================Subgrupo Preentiva=============================================
  function ChamarCadastroSubgrupoPreventiva() {
    sessionStorage.removeItem("dadosFab");
    setQualTitulo('Subgrupo Preventiva')
    setQualGrid(<><FormSubGrupoPreventiva voltar={BtnVoltarCorretiva} /></>)
  }

  async function EditarSubgrupoPreventiva(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosFab", JSON.stringify(obj));
      setQualTitulo('Subgrupo Preventiva')
      setQualGrid(<><FormSubGrupoPreventiva voltar={BtnVoltarCorretiva} /></>)
    }
  }

  function subgrupoPreventivaDeletar(obj: any) {

    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarSubgrupoPreventiva(obj.psg_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }

  function finalizarModal2() {
    setOpenModal(false)
  }

  async function deletarSubgrupoPreventiva(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("preventivesubgroup/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Subgrupo Preventiva')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastroSubgrupoPreventiva}
          Iconeditar={EditarSubgrupoPreventiva}
          iconeExcluir={subgrupoPreventivaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='preventivesubgroup/buscarpreventivesubgroup'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)

      }
    } catch (e: any) {
      setQualTitulo('Subgrupo Preventiva')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroSubgrupoPreventiva}
        Iconeditar={EditarSubgrupoPreventiva}
        iconeExcluir={subgrupoPreventivaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='preventivesubgroup/buscarpreventivesubgroup'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)
    }
  }

  function BtnVoltarCorretiva() {
    setQualTitulo('Subgrupo Preventiva')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
      Iconeadd={ChamarCadastroSubgrupoPreventiva}
      Iconeditar={EditarSubgrupoPreventiva}
      iconeExcluir={subgrupoPreventivaDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='preventivesubgroup/buscarpreventivesubgroup'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      pag="ok"
    /></>)


  }


  //=================================Preentiva=============================================

  function ChamarCadastroTipoPreventiva() {
    sessionStorage.removeItem("dadosFab");
    setQualTitulo('Tipo Preventiva')
    setQualGrid(<><FormTipoPreventiva voltar={BtnVoltarTipoPreventiva} /></>)
  }

  async function EditarTipoPreventiva(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosFab", JSON.stringify(obj));
      setQualTitulo('Tipo Preventiva')
      setQualGrid(<><FormTipoPreventiva voltar={BtnVoltarTipoPreventiva} /></>)
    }
  }

  function tipoPreventivaDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarTipoPreventiva(obj.pvt_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }

  }

  async function deletarTipoPreventiva(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("preventivetype/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Tipo Preventiva')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastroTipoPreventiva}
          Iconeditar={EditarTipoPreventiva}
          iconeExcluir={tipoPreventivaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='preventivetype/buscarpreventivetype'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)


      }
    } catch (e: any) {
      setQualTitulo('Tipo Preventiva')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroTipoPreventiva}
        Iconeditar={EditarTipoPreventiva}
        iconeExcluir={tipoPreventivaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='preventivetype/buscarpreventivetype'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

    }
  }

  function BtnVoltarTipoPreventiva() {
    setQualTitulo('Tipo Preventiva')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
      Iconeadd={ChamarCadastroTipoPreventiva}
      Iconeditar={EditarTipoPreventiva}
      iconeExcluir={tipoPreventivaDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='preventivetype/buscarpreventivetype'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      pag="ok"
    /></>)


  }



  //=================================Corretiva=============================================
  function ChamarCadastroTipoCorretiva() {
    sessionStorage.removeItem("dadosFab");
    setQualTitulo('Tipo Corretiva')
    setQualGrid(<><FormTipoCorretiva voltar={BtnVoltarTipoCorretiva} /></>)
  }

  async function EditarTipoCorretiva(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosFab", JSON.stringify(obj));
      setQualTitulo('Tipo Corretiva')
      setQualGrid(<><FormTipoCorretiva voltar={BtnVoltarTipoCorretiva} /></>)
    }
  }

  function tipoCorretivaDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarTipoCorretiva(obj.crt_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }

  }

  async function deletarTipoCorretiva(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("correctivetype/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Tipo Corretiva')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastroTipoCorretiva}
          Iconeditar={EditarTipoCorretiva}
          iconeExcluir={tipoCorretivaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='correctivetype/buscarcorrectivetype'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          pag="ok"
          ambiente='%'
        /></>)

      }
    } catch (e: any) {
      setQualTitulo('Tipo Corretiva')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroTipoCorretiva}
        Iconeditar={EditarTipoCorretiva}
        iconeExcluir={tipoCorretivaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='correctivetype/buscarcorrectivetype'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        pag="ok"
        ambiente='%'
      /></>)

    }
  }

  function BtnVoltarTipoCorretiva() {
    setQualTitulo('Tipo Corretiva')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
      Iconeadd={ChamarCadastroTipoCorretiva}
      Iconeditar={EditarTipoCorretiva}
      iconeExcluir={tipoCorretivaDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='correctivetype/buscarcorrectivetype'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      pag="ok"
      ambiente='%'
    /></>)

  }

  //=================================Depreciation=============================================
  function ChamarCadastroDepreciation() {
    sessionStorage.removeItem("dadosDpc");
    setQualGrid(<><FormDepreciation voltar={BtnVoltarDepreciation} /></>)
  }


  function BtnVoltarDepreciation() {
    setQualTitulo('Cadastrar Depreciação')
    setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroDepreciation} Iconeditar={EditarDepreciation} iconeExcluir={DepreciationDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='depreciation/buscardepreciation'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente={'%'}
      pag="ok"
    /></>))
  }

  async function EditarDepreciation(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosDpc", JSON.stringify(obj));
      setQualGrid(<><FormDepreciation voltar={BtnVoltarDepreciation} /></>)
    }
  }

  function DepreciationDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => DeletarDepreciation(obj.dpc_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }

  async function DeletarDepreciation(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("depreciation/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {

        setQualTitulo('Cadastrar Depreciação')
        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroDepreciation} Iconeditar={EditarDepreciation} iconeExcluir={DepreciationDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='depreciation/buscardepreciation'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente={'%'}
          pag="ok"
        /></>))
      }
    } catch (e: any) {
      setQualTitulo('Cadastrar Local')
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroDepreciation} Iconeditar={EditarDepreciation} iconeExcluir={DepreciationDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='depreciation/buscardepreciation'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        pag="ok"
      /></>))
    }
  }



  //=================================Unidade=============================================
  function ChamarCadastroContratoUni() {
    sessionStorage.removeItem("dadosCon");
    setQualGrid(<><FormContrato voltar={ChamarCadastroUnidadeform} /></>)
  }

  function ChamarCadastroUnidadeform() {
    setQualGrid(<><FormUnidade voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
  }

  function ChamarCadastroUnidade() {
    sessionStorage.removeItem("dadosUni");
    setQualGrid(<><FormUnidade voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
  }

  function BtnVoltarUnidade() {
    setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroUnidade} Iconeditar={EditarUnidade} iconeExcluir={UnidadeDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='unity/buscarunity'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente={'%'}
      dbtnDelete="N"
      pag="ok"
    /></>))
  }

  async function EditarUnidade(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosUni", JSON.stringify(obj));
      setQualGrid(<><FormUnidade voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
    }
  }


  function UnidadeDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => DeletarUnidade(obj.unt_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }

  async function DeletarUnidade(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("unity/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {

        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroUnidade} Iconeditar={EditarUnidade} iconeExcluir={UnidadeDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='unity/buscarunity'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente={'%'}
          dbtnDelete="N"
          pag="ok"
        /></>))
      }
    } catch (e: any) {
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroUnidade} Iconeditar={EditarUnidade} iconeExcluir={UnidadeDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='unity/buscarunity'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        dbtnDelete="N"
        pag="ok"
      /></>))
    }
  }

  //=================================Unidade=============================================

  //=================================Contrato=============================================
  async function GetContrato() {
    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("contract/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        setDadosContrato(valor);
      } else {
        setDadosContrato([])
      }
    } catch (e) {
      setDadosContrato([])
    }
  }



  function ChamarCadastroContrato() {
    sessionStorage.removeItem("dadosCon");
    setQualGrid(<><FormContrato voltar={BtnVoltarContrato} /></>)
  }

  function BtnVoltarContrato() {
    setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroContrato} Iconeditar={EditarContrato} iconeExcluir={ContratoDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='contract/buscarcontract'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente={'%'}
      dbtnDelete="N"
      pag="ok"
    /></>))
    GetContrato()
  }

  async function EditarContrato(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosCon", JSON.stringify(obj));
      setQualGrid(<><FormContrato voltar={BtnVoltarContrato} /></>)
    }
  }

  function ContratoDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarDeletar(obj.ctc_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }

  async function deletarDeletar(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("contract/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {

        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroContrato} Iconeditar={EditarContrato} iconeExcluir={ContratoDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='contract/buscarcontract'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente={'%'}
          dbtnDelete="N"
          pag="ok"
        /></>))
        GetContrato()

      }
    } catch (e: any) {
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroContrato} Iconeditar={EditarContrato} iconeExcluir={ContratoDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='contract/buscarcontract'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        dbtnDelete="N"
        pag="ok"
      /></>))
    }
  }

  //=================================Contrato=============================================

  //=================================Modelo=============================================
  async function GetModelo(marca: any) {
    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("model/listar", {
        params: {
          id: marca
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;
        setDadosModelo(valor);
      } else {
        setDadosModelo([])
      }
    } catch (e) {

      setDadosModelo([])
    }

  }

  async function EditarModelo(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosFab", JSON.stringify(obj));
      setQualGrid(<><FormModelo voltar={BtnVoltarModelo} /></>)
    }
  }

  function ChamarCadastroModelo() {
    sessionStorage.removeItem("dadosFab");
    setQualGrid(<><FormModelo voltar={BtnVoltarModelo} /></>)
  }

  function BtnVoltarModelo() {
    setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroModelo} Iconeditar={EditarModelo} iconeExcluir={ModeloDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='model/buscarmodel'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente={'%'}
      pag="ok"
    /></>))
    GetModelo('%')

  }

  function ModeloDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarModelo(obj.mde_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }

  async function deletarModelo(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("model/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {

        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroModelo} Iconeditar={EditarModelo} iconeExcluir={ModeloDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='model/buscarmodel'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente={'%'}
          pag="ok"
        /></>))
        GetModelo('%')

      }
    } catch (e: any) {
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroModelo} Iconeditar={EditarModelo} iconeExcluir={ModeloDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='model/buscarmodel'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        pag="ok"
      /></>))
    }
  }
  //=================================Modelo=============================================

  //=================================Fabricant=============================================
  async function GetFabricante() {
    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("manufacturer/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        setDadosFabricante(valor);
      } else {
        setDadosFabricante([])
      }
    } catch (e) {
      setDadosFabricante([])
    }
  }

  async function EditarFabricante(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosFab", JSON.stringify(obj));
      setQualTitulo('Cadastrar Fabricante')
      setQualGrid(<><FormFabricantes voltar={BtnVoltarFabricante} /></>)
    }
  }

  function ChamarCadastroFabricante() {
    sessionStorage.removeItem("dadosFab");
    setQualTitulo('Cadastrar Fabricante')
    setQualGrid(<><FormFabricantes voltar={BtnVoltarFabricante} /></>)
  }

  function BtnVoltarFabricante() {
    setQualTitulo('Cadastrar Fabricante')
    setQualGrid(<><GridSecundario
      PegarValorGrid={PegarValorGrid}
      Iconeadd={ChamarCadastroFabricante}
      Iconeditar={EditarFabricante}
      iconeExcluir={FabricanteDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='manufacturer/buscarmanufacturer'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      pag="ok"
    /></>)

    GetFabricante();
  }

  function FabricanteDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarFabricante(obj.mfr_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }

  }

  async function deletarFabricante(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("manufacturer/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Cadastrar Fabricante')
        setQualGrid(<><GridSecundario
          PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastroFabricante}
          Iconeditar={EditarFabricante}
          iconeExcluir={FabricanteDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='manufacturer/buscarmanufacturer'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)
        GetFabricante();
      }
    } catch (e: any) {
      setQualGrid(<><GridSecundario
        PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastroFabricante}
        Iconeditar={EditarFabricante}
        iconeExcluir={FabricanteDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='manufacturer/buscarmanufacturer'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)
    }
  }
  //=================================Fabricant=============================================

  //=================================Marca=============================================

  async function GetMarca(fabricante: any) {
    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("brand/listar", {
        params: {
          id: fabricante
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;
        setDadosMarca(valor);
      } else {
        setDadosMarca([])
      }
    } catch (e) {
      setDadosMarca([])
    }

  }

  async function EditarMarca(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosFab", JSON.stringify(obj));
      setQualTitulo('Cadastrar Marca')
      setQualGrid(<><FormMarca voltar={BtnVoltarMarca} manufacturer={'%'} /></>)
    }
  }

  function ChamarCadastroMarca() {
    sessionStorage.removeItem("dadosFab");
    setQualTitulo('Cadastrar Marca')
    setQualGrid(<><FormMarca voltar={BtnVoltarMarca} manufacturer={'%'} /></>)
  }

  function BtnVoltarMarca() {
    setQualTitulo('Cadastrar Marca')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroMarca} Iconeditar={EditarMarca} iconeExcluir={MarcaDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='brand/buscarbrand'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente=''
      pag="ok"
    /></>)
    GetMarca("%");
  }

  function MarcaDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarMarca(obj.brn_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }

  async function deletarMarca(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("brand/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Cadastrar Marca')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroMarca} Iconeditar={EditarMarca} iconeExcluir={MarcaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='brand/buscarbrand'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente=''
          pag="ok"
        /></>)
        GetMarca("%");

      }
    } catch (e: any) {
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroMarca} Iconeditar={EditarMarca} iconeExcluir={MarcaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='brand/buscarbrand'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente=''
        pag="ok"
      /></>)
    }
  }
  //=================================Marca=============================================
  //=================================department=============================================

  function ChamarCadastroDepartamento() {
    sessionStorage.removeItem("dadosDep");
    setQualGrid(<><FormDepartment voltar={BtnVoltarDepartamento} manual={'SIM'} /></>)
  }

  async function EditarDepartment(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosDep", JSON.stringify(obj));
      setQualGrid(<><FormDepartment voltar={BtnVoltarDepartamento} manual={'SIM'} /></>)
    }
  }

  function BtnVoltarDepartamento() {
    setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroDepartamento} Iconeditar={EditarDepartment} iconeExcluir={DepartamentoDeletar}
      th1='Ativo'
      th2='Descrição'
      th3='Identificador Alternativo'
      th4='Subcategoria'
      th5='Data da Inclusão'
      th6='Data Atualização'
      endPoint='local/listarlocal'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente={'%'}
      dbtnDelete="N"
    /></>))
  }


  function DepartamentoDeletar(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => DeletarDepartamento(obj.loc_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }

  }

  async function DeletarDepartamento(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("local/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Cadastrar Local')
        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroDepartamento} Iconeditar={EditarDepartment} iconeExcluir={DepartamentoDeletar}
          th1='Ativo'
          th2='Descrição'
          th3='Identificador Alternativo'
          th4='Subcategoria'
          th5='Data da Inclusão'
          th6='Data Atualização'
          endPoint='local/listarlocal'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente={'%'}
          dbtnDelete="N"
        /></>))
      }
    } catch (e: any) {
      setQualTitulo('Cadastrar Local')
      setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroDepartamento} Iconeditar={EditarDepartment} iconeExcluir={DepartamentoDeletar}
        th1='Ativo'
        th2='Descrição'
        th3='Identificador Alternativo'
        th4='Subcategoria'
        th5='Data da Inclusão'
        th6='Data Atualização'
        endPoint='local/listarlocal'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente={'%'}
        dbtnDelete="N"
      /></>))
    }
  }

  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }


  function PegarValorGrid(valor: any) {


  }


  //=================================grupo de tarefas=============================================
  function ChamarCadastrogrupotarefa() {
    sessionStorage.removeItem("dadosTipoEquipe");
    setQualTitulo('Grupo da tarefa')
    setQualGrid(<><FormTaskGroup voltar={BtnVoltargrupotarefa} /></>)
  }

  async function Editargrupotarefa(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosTipoEquipe", JSON.stringify(obj));
      setQualTitulo('Grupo da tarefa')
      setQualGrid(<><FormTaskGroup voltar={BtnVoltargrupotarefa} /></>)
    }
  }

  function grupotarefaDeletar(obj: any) {

    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletargrupotarefa(obj.tgp_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }


  async function deletargrupotarefa(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("correctivesubgroup/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Grupo da tarefa')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastrogrupotarefa}
          Iconeditar={Editargrupotarefa}
          iconeExcluir={grupotarefaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='taskgroup/buscar'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)

      }
    } catch (e: any) {
      setQualTitulo('Grupo da tarefa')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastrogrupotarefa}
        Iconeditar={Editargrupotarefa}
        iconeExcluir={grupotarefaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='taskgroup/buscar'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

    }
  }

  function BtnVoltargrupotarefa() {
    setQualTitulo('Grupo da tarefa')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
      Iconeadd={ChamarCadastrogrupotarefa}
      Iconeditar={Editargrupotarefa}
      iconeExcluir={grupotarefaDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='taskgroup/buscar'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      pag="ok"
    /></>)
  }

  //==============================================================================

  //=================================tipo de tarefas=============================================
  function ChamarCadastrotipotarefa() {
    sessionStorage.removeItem("dadosTipoTarefa");
    setQualTitulo('Tipo de Tarefa')
    setQualGrid(<><FormTipoTarefa voltar={BtnVoltartipotarefa} /></>)
  }

  async function Editartipotarefa(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosTipoTarefa", JSON.stringify(obj));
      setQualTitulo('Tipo de Tarefa')
      setQualGrid(<><FormTipoTarefa voltar={BtnVoltartipotarefa} /></>)
    }
  }

  function tipotarefaDeletar(obj: any) {

    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletartipotarefa(obj.tty_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }


  async function deletartipotarefa(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("tasktype/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Tipo de Tarefa')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastrotipotarefa}
          Iconeditar={Editartipotarefa}
          iconeExcluir={tipotarefaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='tasktype/buscartasktype'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)

      }
    } catch (e: any) {
      setQualTitulo('Tipo de Tarefa')
      setQualGrid(<><GridSecundario 
        PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastrotipotarefa}
        Iconeditar={Editartipotarefa}
        iconeExcluir={tipotarefaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='tasktype/buscartasktype'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

    }
  }

  function BtnVoltartipotarefa() {
    setQualTitulo('Tipo de Tarefa')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
      Iconeadd={ChamarCadastrotipotarefa}
      Iconeditar={Editartipotarefa}
      iconeExcluir={tipotarefaDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='tasktype/buscartasktype'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      pag="ok"
    /></>)
  }

  //==============================================================================

//=================================COLABORADORES=============================================

function ChamarCadastrocolaboradores() {
  sessionStorage.removeItem("dadosTipoTarefa");
  setQualTitulo('Colaboradores')
  setQualGrid(<><FormColaborador voltar={BtnVoltarcolaboradores} /></>)
}

async function Editarcolaboradores(obj: any) {
  if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
    sessionStorage.setItem("dadosTipoTarefa", JSON.stringify(obj));
    setQualTitulo('Colaboradores')
    setQualGrid(<><FormColaborador voltar={BtnVoltarcolaboradores} /></>)
  }
}

function colaboradoresDeletar(obj: any) {

}
  
async function deletarcolaboradores(id: any) {
 
}

function BtnVoltarcolaboradores() {
  setQualTitulo('Colaboradores')
  setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
    Iconeadd={ChamarCadastrotipotarefa}
    Iconeditar={Editartipotarefa}
    iconeExcluir={tipotarefaDeletar}
    th1='Ativo'
    th2='ID'
    th3='Descrição'
    th4='Identificador Alternativo'
    th5=''
    th6=''
    endPoint='tasktype/buscartasktype'
    consult='N'
    col1='1'
    col2='2'
    col3='3'
    ambiente='%'
    pag="ok"
  /></>)
}

//==============================================================================
  return (
    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>
          <div className='containerPrincipal'>
            <div className='div-titulo-pages'>
              <span className='fa fa-list' />
              <h1 className='div-titulo-pages-text'>Meus Cadastros</h1>
            </div>
            {exibirGrid}
          </div>
        </main>
      </div>


      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
            <div onClick={fecharOpenModalIncluirNovo}>
              <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>

          <div className='scrol-modal'>
            {qualGrid}
          </div>
        </>
      </ModalIncluirNovo>


      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </>
  );

}

export default MeusCadastros;