
import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import WalletBox from '../../components/WalletBox';
import Featured from '../../components/featured/Featured';
import Table from "../../components/table/Table";
import './index.css'
import BarChartComponent from '../../components/BarChartComponent/BarChartComponent';
import ProgressBar from '../../components/ProgressBar'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import { FcFilledFilter, FcClearFilters } from "react-icons/fc";
import Loading from '../../components/Loading';
import Aviso01 from '../../components/Aviso01';
import ExportToExcel from '../../components/exportexcel/index'
import Modal, {
    ModalHeader,
    ModalBody,
    useModal,
} from "../../components/Modal/Modal";
import GridVisualizacao from '../../components/GridVisualizacao'
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'
import NewWalletBox from '../../components/NewWalletBox'
import DonutChart from '../../components/DonutChart'
import PreventivasCorretivasChart from '../../components/PreventivasCorretivasChart';
import ClassificacaoChart from '../../components/ClassificacaoChart';
import SLAChart from '../../components/SLAChart'
import Task from '../Tarefas';

function Dashboard() {
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [porcentagemtexto, setPorcentagemtexto] = useState('');
    const [porcentagem, setPorcentagem] = useState('');
    const [foraGarantia, setForaGarantia] = useState('0');
    const [totalativos, setTotalativos] = useState('0');
    const [naGarantia, setnaGarantia] = useState('0');
    const [proximoVencer, setproximoVencer] = useState('0');
    const [estoque, setEstoque] = useState('0');
    const [utilizado, setUtilizado] = useState('0');
    const [foraUso, setForaUso] = useState('0');
    const [totalLocal, setTotalLocal] = useState('0');
    const [optionsAtivosCategoria, setOptionsAtivosCategoria] = useState([])
    const [optionsAtivosPorContrato, setOptionsAtivosPorContrato] = useState([])
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

    const [componenteTable1, setComponenteTable1] = useState(<></>)
    const [titleTable1, setTitleTable1] = useState(<></>)


    const [componenteTable2, setComponenteTable2] = useState(<></>)
    const [titleTable2, setTitleTable2] = useState(<></>)


    const [barChart, setBarChart] = useState(<></>)
    const { isShowing, toggle } = useModal();
    const [qualInf, setQualInf] = useState(<></>)
    const [titulo, setTitulo] = useState('');

    const [arrayEstoque, setArrayEstoque] = useState([])
    const [arrayUtilizado, setArrayUtilizado] = useState([])
    const [arrayForaUso, setArrayForaUso] = useState([])
    const [arrayTotal, setArrayTotal] = useState([])
    const [arrayTotalLocal, setArrayTotalLocal] = useState([])

    const [totalChamados, setTotalChamados] = useState('0');
    const [totalPententes, setTotalPententes] = useState('0');
    const [totalConcluidos, setTotalConcluidos] = useState('0');

    useEffect(() => {

        validatToken();


    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {


                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                await GetLocal();
                await GetGarantia();
                await GetListarAtivos();
                await GetListarativosCategorias();
                await GetAtivosPorContrato();
                await GetAtivosPorContratoDanificado();
                await task()
            }

        } catch (e: any) {

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }

    }

   async function task(){

        let dados = []

        dados.push({
            tsk_active: '1',
            tsk_id: '',
            tea_id: '',
            age_id: '',
            loc_id: '',
            ast_id: '',
            tsk_integrationid: '',
            stn_id: '',
            tsk_observation: '',
            tty_id: '',
            crt_id: '',
            pvt_id: '',
            pla_id: '',
            startdate: '',
            enddate: '',
            search: '',
            startdateUlt: '',
            enddateUlt: '',
            age_id_insert: null,
          });
      

      
          api.get('task/buscartaskportal', {
            params: { data: dados }
          })
            .then((res) => {
              var data = res.data.data;

              
              const total = data.filter(
                (e: any) => {
                    return  e.age_id !== 1 && e.tsk_active === 1
                }
            );

              setTotalChamados(String(total.length))


              const pendentes = data.filter(
                (e: any) => {
                    return (e.stn_id !== 50 && e.stn_id !==60) && e.age_id !== 1 && e.tsk_active === 1
                }
            );

            setTotalPententes(String(pendentes.length))

            const concluido = data.filter(
                (e: any) => {
                    return (e.stn_id === 50 ) && e.age_id !== 1 && e.tsk_active === 1
                }
            );

            setTotalConcluidos(String(concluido.length))

            })
            .catch(function (e) {
             
            });
        }
    

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    async function GetLocal() {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/totallocal")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setTotalLocal(valor.length)
                setArrayTotalLocal(valor)
            }
        } catch (e: any) {

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }


    }

    async function GetGarantia() {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/listargarantia")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const vencido = valor.filter(
                    (e: any) => {
                        return e.VENCIDO === 1
                    }
                );

                const emgarantia = valor.filter(
                    (e: any) => {
                        return e.EMGARANTIA === 1
                    }
                );

                const avencer = valor.filter(
                    (e: any) => {
                        return e.AVENCER === 1
                    }
                );


                setForaGarantia(vencido.length);
                setTotalativos(valor.length);
                setnaGarantia(emgarantia.length);
                setproximoVencer(avencer.length);


                setPorcentagemtexto((parseInt(vencido.length) / parseInt(valor.length) * 100).toFixed(2) + '%');
                setPorcentagem((parseInt(vencido.length) / parseInt(valor.length) * 100).toFixed(2));

            }
        } catch (e: any) {

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }


    }

    function fecharModal2() {
        setOpenModal(false)
    }

    async function GetListarAtivos() {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/listarativos")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setArrayTotal(valor)
                const estoque = valor.filter(
                    (e: any) => {
                        return e.ssa_id === 1
                    }
                );

                setEstoque(estoque.length)

                setArrayEstoque(estoque)
                const utilizado = valor.filter(
                    (e: any) => {
                        return e.ssa_id === 2
                    }
                );

                setArrayUtilizado(utilizado)
                setUtilizado(utilizado.length)

                const foraUso = valor.filter(
                    (e: any) => {
                        return e.ssa_id === 3
                    }
                );
                setArrayForaUso(foraUso)
                setForaUso(foraUso.length)

            }
        } catch (e: any) {
            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }
    }

    async function GetListarativosCategorias() {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/listarativoscategorias")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsAtivosCategoria(valor)

            }
        } catch (e: any) {
            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }
    }


    async function GetAtivosPorContrato() {
        setTitleTable1(<div className="cont-listTitle"><h1 className='listTitle'>Valor Total de Ativos por Contrato</h1></div>)
        setComponenteTable1(<><Loading /></>)
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/ativosporcontrato")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                if (valor.length > 0) {

                    const arr1 = [] as any;
                    let result1 = valor;
                    result1.map((user: any) => {
                        return arr1.push({ id: user.valor1, contrato: user.valor2, quantidade: user.valor3, valor: user.valor4 });
                    });

                    const arr3 = [] as any;
                    let result3 = valor;

                    result3.map((user: any) => {

                        var valorAlterado = user.valor4;
                        if (valorAlterado !== null && valorAlterado !== undefined) {
                            valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
                            valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
                            valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
                        } else {
                            valorAlterado = '0'; // Ou qualquer valor padrão que você queira usar quando valor4 é nulo
                        }

                        return arr3.push({ valor1: user.valor1, valor2: user.valor2, valor3: user.valor3, valor4: valorAlterado });
                    });

                    setTitleTable1(<div className="cont-listTitle"><h1 className='listTitle'>Valor Total de Ativos por Contrato</h1>  <div className='icon-tab'><ExportToExcel data={arr1} title={'Valor Total de Ativos por Contrato'} />  <FcFilledFilter className='icon-filter' /></div></div>)
                    setOptionsAtivosPorContrato(valor)
                    setComponenteTable1(<Table
                        title1={'ID'}
                        title2={'Contrato'}
                        title3={'Quantidade'}
                        title4={'Valor Total'}
                        data={arr3}
                        setarValor={GetListarPorContrato} />)

                    const arr2 = [] as any;
                    let result2 = valor;
                    result2.map((user: any) => {
                        return arr2.push({ Ativos: user.valor3, name: user.valor2 });
                    });

                    setBarChart(<BarChartComponent data={arr2} />)

                } else {

                    setComponenteTable1(<><Aviso01 /></>)
                    setBarChart(<><Aviso01 /></>)

                }

            } else {
                setComponenteTable1(<><Aviso01 /></>)
                setBarChart(<><Aviso01 /></>)
            }
        } catch (e: any) {
            setComponenteTable1(<><Aviso01 /></>)
            setBarChart(<><Aviso01 /></>)

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }
    }


    async function GetListarPorContrato(id: any, params1: any, params2: any) {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/listarporcontrato", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
             

                valor = ret.data.data;
                const arr1 = [] as any;
                let result1 = valor;
                result1.map((user: any) => {
                    return arr1.push({ id: user.valor1, contrato: user.valor2, quantidade: user.valor3, valor: user.valor4 });
                });

                setTitleTable1(<div className="cont-listTitle">
                    <h1 className='listTitle'>Valor Ativos Por Contrato {params1}</h1>
                    <div className='icon-tab'><ExportToExcel data={arr1}
                        title={`Valor Ativos Contrato ${params1}`} />
                        <FcClearFilters onClick={GetAtivosPorContrato} className='icon-filter' /></div></div>)

                const arr2 = [] as any;
                let result2 = valor;

                result2.map((user: any) => {

                    var valorAlterado = user.valor4;
                    if (valorAlterado !== null && valorAlterado !== undefined) {
                        valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
                        valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
                        valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
                    } else {
                        valorAlterado = '0'; // Ou qualquer valor padrão que você queira usar quando valor4 é nulo
                    }


                    return arr2.push({ valor1: user.valor1, valor2: user.valor2, valor3: user.valor3, valor4: valorAlterado });

                });

                setComponenteTable1(<Table
                    title1={'ID'}
                    title2={'Unidade'}
                    title3={'Quantidade'}
                    title4={'Valor Total'}
                    data={arr2}
                    setarValor={GetListarPorUnidade} />)
            }
        } catch (e: any) {

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }

        }

    }


    async function GetListarPorUnidade(id: any, params1: any, params2: any) {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''


        try {
            ret = await api.get("dashboard/listarporunidade", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr1 = [] as any;
                let result1 = valor;
                result1.map((user: any) => {
                    return arr1.push({ id: user.valor1, contrato: user.valor2, departamento: user.valor3, valor: user.valor4 });
                });

                setTitleTable1(<div className="cont-listTitle">
                    <h1 className='listTitle'>Valor Ativos {params1}</h1>
                    <div className='icon-tab'><ExportToExcel data={arr1}
                        title={`Valor Ativos Unidade ${params1}`} />
                        <FcClearFilters onClick={GetAtivosPorContrato} className='icon-filter' /></div></div>)


                const arr2 = [] as any;
                let result2 = valor;

                result2.map((user: any) => {
                    var valorAlterado = user.valor4;
                    if (valorAlterado !== null && valorAlterado !== undefined) {
                        valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
                        valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
                        valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
                    } else {
                        valorAlterado = '0'; // Ou qualquer valor padrão que você queira usar quando valor4 é nulo
                    }

                    return arr2.push({ valor1: user.valor1, valor2: user.valor2, valor3: user.valor3, valor4: valorAlterado });
                });


                setComponenteTable1(
                    <Table
                        title1={'ID'}
                        title2={'Descrição'}
                        title3={'Departamento'}
                        title4={'Valor Total'}
                        data={arr2}
                        setarValor={UltimaSelecao} />)
            }
        } catch (e: any) {

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }

    }

    function UltimaSelecao(id: any, params1: any, params2: any) {

    }

    //------------------------------------table2



    async function GetAtivosPorContratoDanificado() {
        setTitleTable2(<div className="cont-listTitle"><h1 className='listTitle'>Valor Total de Ativos Danificados por Contrato</h1></div>)
        setComponenteTable2(<><Loading /></>)
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/ativosporcontratodanificado")
            if (ret.data.status === 200) {
                valor = ret.data.data;

       
                if (valor.length > 0) {

                    const arr1 = [] as any;
                    let result1 = valor;
                    result1.map((user: any) => {
                        return arr1.push({ id: user.valor1, contrato: user.valor2, quantidade: user.valor3, valor: user.valor4 });
                    });

                    setTitleTable2(<div className="cont-listTitle">
                        <h1 className='listTitle'>Valor Total de Ativos Danificados por Contrato</h1>
                        <div className='icon-tab'><ExportToExcel data={arr1}
                            title={'Valor Total de Ativos Danificados por Contrato'} />
                            <FcFilledFilter className='icon-filter' /></div></div>)

                    setOptionsAtivosPorContrato(valor)

                    const arr2 = [] as any;
                    let result2 = valor;

                    result2.map((user: any) => {

                        var valorAlterado = user.valor4;
                       
                        if (valorAlterado !== null && valorAlterado !== undefined) {
                            valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
                            valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
                            valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
                        } else {
                            valorAlterado = '0'; // Ou qualquer valor padrão que você queira usar quando valor4 é nulo
                        }


                        return arr2.push({ valor1: user.valor1, valor2: user.valor2, valor3: user.valor3, valor4: valorAlterado });
                    });

                    setComponenteTable2(<Table
                        title1={'ID'}
                        title2={'Contrato'}
                        title3={'Quantidade'}
                        title4={'Valor Total'}
                        data={arr2}
                        setarValor={GetListarPorContratooDanificado} />)

                } else {
                    setComponenteTable2(<><Aviso01 /></>)
                }

            } else {
                setComponenteTable2(<><Aviso01 /></>)
            }
        } catch (e: any) {

            setComponenteTable2(<><Aviso01 /></>)

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }
    }

    async function GetListarPorContratooDanificado(id: any, params1: any, params2: any) {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/listarporcontratoodanificado", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr1 = [] as any;
                let result1 = valor;
                result1.map((user: any) => {
                    return arr1.push({ id: user.valor1, contrato: user.valor2, quantidade: user.valor3, valor: user.valor4 });
                });


                setTitleTable2(<div className="cont-listTitle">
                    <h1 className='listTitle'>Valor Ativos Danificados Contrato {params1}</h1>
                    <div className='icon-tab'><ExportToExcel data={arr1}
                        title={`Valor Ativos Danificados Contrato ${params1}`} />
                        <FcClearFilters onClick={GetAtivosPorContratoDanificado} className='icon-filter' /></div></div>)


                const arr2 = [] as any;
                let result2 = valor;

                result2.map((user: any) => {
                    var valorAlterado = user.valor4;
                    if (valorAlterado !== null && valorAlterado !== undefined) {
                        valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
                        valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
                        valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
                    } else {
                        valorAlterado = '0'; // Ou qualquer valor padrão que você queira usar quando valor4 é nulo
                    }


                    return arr2.push({ valor1: user.valor1, valor2: user.valor2, valor3: user.valor3, valor4: valorAlterado });
                });

                setComponenteTable2(<Table
                    title1={'ID'}
                    title2={'Contrato'}
                    title3={'Quantidade'}
                    title4={'Valor Total'}
                    data={arr2}
                    setarValor={GetListarPorUnidadeDanificado} />)
            }
        } catch (e: any) {

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }
    }



    async function GetListarPorUnidadeDanificado(id: any, params1: any, params2: any) {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {
            ret = await api.get("dashboard/listarporunidadedanificado", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr1 = [] as any;
                let result1 = valor;
                result1.map((user: any) => {
                    return arr1.push({ id: user.valor1, contrato: user.valor2, departamento: user.valor3, valor: user.valor4 });
                });


                const arr2 = [] as any;
                let result2 = valor;

                result2.map((user: any) => {
                    var valorAlterado = user.valor4;
                    if (valorAlterado !== null && valorAlterado !== undefined) {
                        valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
                        valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
                        valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
                    } else {
                        valorAlterado = '0'; // Ou qualquer valor padrão que você queira usar quando valor4 é nulo
                    }


                    return arr2.push({ valor1: user.valor1, valor2: user.valor2, valor3: user.valor3, valor4: valorAlterado });
                });

                setTitleTable2(<div className="cont-listTitle">
                    <h1 className='listTitle'>Valor Ativos Danificados {params1}</h1>
                    <div className='icon-tab'><ExportToExcel data={arr1}
                        title={`Valor Ativos Danificados Unidade ${params1}`} />
                        <FcClearFilters onClick={GetAtivosPorContratoDanificado}
                            className='icon-filter' /></div></div>)

                setComponenteTable2(<Table
                    title1={'ID'}
                    title2={'Descrição'}
                    title3={'Departamento'}
                    title4={'Valor Total'}
                    data={arr2}
                    setarValor={UltimaSelecao} />)


            }
        } catch (e: any) {
            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }

        }

    }
    //----------------------------------

    function chamarModalTotal() {
        setTitulo('Total de Ativos')
        setOpenModalIncluirNovo(true)
        setQualInf(<GridVisualizacao
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Nota Fiscal'
            th5='Valor'
            th6='Data da Inclusão'
            col1='1'
            col2='0'
            col3='2'
            col4='3'
            col5='4'
            col6='5'
            data={arrayTotal} />);
    }


    function chamarModalLocal() {
        setTitulo('Total de Ativos')

        setOpenModalIncluirNovo(true)
        setQualInf(<GridVisualizacao
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5='Pavimento'
            th6='Data da Inclusão'
            col1='1'
            col2='0'
            col3='2'
            col4='3'
            col5='4'
            col6='9'
            data={arrayTotalLocal} />);
    }

    function chamarModalEstoque() {
        setTitulo('Em Estoque')
        setOpenModalIncluirNovo(true)
        setQualInf(<GridVisualizacao
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Nota Fiscal'
            th5='Valor'
            th6='Data da Inclusão'
            col1='1'
            col2='0'
            col3='2'
            col4='3'
            col5='4'
            col6='5'
            data={arrayEstoque} />);
    }

    function chamarModalUtilizado() {
        setTitulo('Em Uso')
        setOpenModalIncluirNovo(true)
        setQualInf(<GridVisualizacao
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Nota Fiscal'
            th5='Valor'
            th6='Data da Inclusão'
            col1='1'
            col2='0'
            col3='2'
            col4='3'
            col5='4'
            col6='5'
            data={arrayUtilizado} />);
    }

    function chamarModalForaUso() {
        setTitulo('Fora de Uso')
        setOpenModalIncluirNovo(true)
        setQualInf(<GridVisualizacao
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Nota Fiscal'
            th5='Valor'
            th6='Data da Inclusão'
            col1='1'
            col2='0'
            col3='2'
            col4='3'
            col5='4'
            col6='5'
            data={arrayForaUso} />);
    }

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <div className='container-card-inicial'>
                    <div className="container-cad-dash1">
                        <WalletBox
                            title="Total de Locais"
                            color="#f39c12"
                            icon="arrowUp"
                            icone="fa fa-loc"
                            valor={totalLocal}
                            chamarModalTotal={chamarModalLocal}
                        />
                        <WalletBox
                            title="Total de Ativos"
                            color="#00c0ef"
                            icon="arrowUp"
                            icone="fa fa-layer-group"
                            valor={totalativos}
                            chamarModalTotal={chamarModalTotal}
                        />
                        <WalletBox
                            title="Em Operação"
                            color="#00a65a"
                            icon="arrowUp"
                            icone="fa fa-building"
                            valor={utilizado}
                            chamarModalTotal={chamarModalUtilizado}
                        />
                        <WalletBox
                            title="Danificados"
                            color="#dd4b39"
                            icon="arrowUp"
                            icone="fa fa-exclamation-triangle"
                            valor={foraUso}
                            chamarModalTotal={chamarModalForaUso}
                        />
                    </div>
                </div>

                <div className='container-card-inicial'>
                    <div className="container-cad-dash1">
                        <NewWalletBox
                            title="Total de Chamados"
                            icone="chamados"
                            frase="Quantidade de chamados atualmente abertos."
                            valor={totalChamados}
                        />
                         <NewWalletBox
                            title="Pendentes"
                            icone="execucao"
                            frase="Quantidade de chamados que ainda não foram concluídos."
                            valor={totalPententes}
                        />
                         <NewWalletBox
                            title="Concluídos"
                            icone="concluidos"
                            frase="Quantidade de chamados que foram finalizados."
                            valor={totalConcluidos}
                        />
                         <NewWalletBox
                            title="Programadas"
                            icone="programadas"
                            frase="Quantidade de manutenções preventivas."
                            valor={'0'}
                        />
                    </div>
                </div>



                <div className="container-cad-dash4">

                    <div id="content1">
                        <Featured title={'Garantia'}
                            CircularProgressbarTexto={porcentagemtexto}
                            CircularProgressbarPorcentagem={porcentagem}
                            foraGarantia={foraGarantia}
                            totalativos={totalativos}
                            naGarantia={naGarantia}
                            proximoVencer={proximoVencer}
                        />
                    </div>

                    <div id="content2">
                        <ProgressBar obj={optionsAtivosCategoria} totalativos={totalativos} />
                    </div>
                    <div id="content3">
                        <DonutChart/>
                    </div>


                </div>

                <div className="container-cad-dash5">
                    <div id="content1">
                        <PreventivasCorretivasChart/>
                    </div>
                </div>

                <div className="container-cad-dash6">
                <div id="content1">
                    <ClassificacaoChart/>
                </div>
                <div id="content2">
                <SLAChart/>
                    </div>
                </div>

                
                <div className="container-cad-dash2">
                    <div id="content1">
                        <div className="listContainer">
                            {titleTable1}

                            <div className="alin-listContainer">
                                {componenteTable1}
                            </div>
                        </div>
                    </div>
                    <div id="content2">
                        <div className="listContainer">
                            {titleTable2}

                            <div className="alin-listContainer">
                                {componenteTable2}
                            </div>
                        </div>
                    </div>

                    
                </div>

             

            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <div className='title-modal-incluir'>
                    <h1 className="modal-IncluirNovo-title">{titulo}</h1>
                    <div onClick={fecharOpenModalIncluirNovo}>
                        <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
                <div className='scrol-modal'>

                    {qualInf}

                </div>
            </ModalIncluirNovo>


            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>


        </>
    );

}

export default Dashboard;