import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import ModalAlert from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import Beautiful from '../../components/Beautiful'
import GridSecundario from '../../components/GridSecundario'
import FormTipoAtividade from '../../components/FormTipoAtividade'
import DualListBox from "react-dual-listbox";
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import FormContrato from '../../components/FormContrato';
import FormUnidade from '../../components/FormUnidade';
import FormTipoLocal from '../../components/FormTipoLocal';

function LocalAdd() {
    const navigate = useNavigate();

    const [qualTitulo, setQualTitulo] = useState('')
    const [qualGrid, setQualGrid] = useState(<></>)
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [titulo, setTitulo] = useState('');

    const [loc_description, setLoc_description] = useState('')
    const [loc_alternativeidentifier, setLoc_alternativeidentifier] = useState('')
    const [loc_active, setLoc_active] = useState(true)
    const [loc_pavement, setLoc_pavement] = useState('')
    const [lty_id, setLty_id] = useState('')
    const [ctc_id, setCtc_id] = useState('')
    const [unt_id, setUnt_id] = useState('')
    const [loc_phoneddd, setLoc_phoneddd] = useState('');
    const [loc_phoneddi, setLoc_phoneddi] = useState('');
    const [loc_phone, setLoc_phone] = useState('');
    const [loc_mobilephoneddd, setLoc_mobilephoneddd] = useState('');
    const [loc_mobilephoneddi, setLoc_mobilephoneddi] = useState('');
    const [loc_mobilephone, setLoc_mobilephone] = useState('');
    const [loc_email, setLoc_email] = useState('');
    const [loc_observation, setLoc_observation] = useState('');

    const [optionContract, setOptionContract] = useState([])
    const [optionUnity, setOptionUnity] = useState([])
    const [optionTipoLocal, setOptionTipoLocal] = useState([])


    const [loc_activeDisabled, setLoc_activeDisabled] = useState(false)
    const [loc_descriptionDisabled, setLoc_descriptionDisabled] = useState(false)
    const [loc_pavementDisabled, setLoc_pavementDisabled] = useState(false)
    const [loc_alternativeidentifierDisabled, setLoc_alternativeidentifierDisabled] = useState(false)
    const [ctc_idDisabled, setCtc_idDisabled] = useState(false)
    const [unt_idDisabled, setUnt_idDisabled] = useState(false)
    const [lty_idDisabled, setLty_idDisabled] = useState(false)
    const [mobilePhonedddDisabled, setMobilePhonedddDisabled] = useState(false)
    const [mobilePhoneddiDisabled, setMobilePhoneddiDisabled] = useState(false)
    const [mobilePhoneDisabled, setMobilePhoneDisabled] = useState(false)
    const [phoneddiDisabled, setPhoneddiDisabled] = useState(false)
    const [phonedddDisabled, setPhoneddDDisabled] = useState(false)
    const [phoneDisabled, setPhoneDisabled] = useState(false)
    const [mailDisabled, setMailDisabled] = useState(false)
    const [observationDisabled, setObservationDisabled] = useState(false)

    const [datetimeinsert, setDatetimeinsert] = useState('')
    const [datetimeupdate, setDatetimeupdate] = useState('')


    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [confirmarUserDisabled, setConfirmarUserDisabled] = useState(false)
    const [cancelarDisabled, setCancelarDisabled] = useState(false)

    const [optionsLocal, setOptionsLocal] = useState([])

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)


        let today = new Date();
        let date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
        let time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
        let JuntarDate = date + "T" + time;
        setDatetimeinsert(JuntarDate)

        validatToken();

    }, []);

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                GetLocal();
                GetContrato();
                GetLocaltype();

                if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {


                } else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {



                }

                setOpenModal(false)

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }

    }


    async function GetLocal() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("local/verificacaolocal")
            if (ret.data.status === 200) {
                valor = ret.data.data;


                setOptionsLocal(valor)

            }
        } catch (e) {
            setOptionsLocal([])
        }
    }


    async function GetLocaltype() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("localtype/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.lty_id), label: user.lty_description });
                });
                setOptionTipoLocal(arr)
            }
        } catch (e) {
            setOptionTipoLocal([])
        }
    }

    async function GetContrato() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("contract/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctc_id), label: user.ctc_description });
                });
                setOptionContract(arr)
            }
        } catch (e) {
            setOptionContract([])
        }
    }

    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }



    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function ValitarActive() {
        let e = document.getElementById("checkbox-active") as HTMLInputElement;

        if (e.checked) {

            setLoc_active(true)
        } else {

            setLoc_active(false)
        }
    }


    function cancelar() {
        navigate('/local');
    }

    function IncluirContrato(Contrato: any) {


        if (Contrato === '' || Contrato === 'Selecione...') {
            setUnt_id('')
            setCtc_id('')
            setOptionUnity([])
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            GetUnidadeSel(Contrato)
            setCtc_id(Contrato)
        }
    }

    function fecharModal() {
        setOpenModal(false)
    }


    async function GetUnidadeSel(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
            setUnt_id('')
            setOptionUnity([])

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {

            setOptionUnity([])

            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;


                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id), label: user.unt_description });
                    });
                    setOptionUnity(arr)

                }
                setOpenModal(false)
            } catch (e) {
                setOpenModal(false)
                setOptionUnity([]);
            }
        }

    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };

    //-------------------------------------------------ADD TIPO LOCAL

    function incluirtipoLocal() {

        setTitulo('Cadastrar Tipo de Local')
        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastrotipoLocal}
            Iconeditar={EditartipoLocal}
            iconeExcluir={tipoLocalDeletar}
            PegarValorGrid={PegarValorGridtipoLocal}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='localtype/buscarlocaltype'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            pag="ok"
        /></>))

        setOpenModalIncluirNovo(true)

    }


    function ChamarCadastrotipoLocal() {
        sessionStorage.removeItem("dadosCon");
        setQualGrid(<><FormTipoLocal voltar={BtnVoltartipoLocal} /></>)
    }

    async function EditartipoLocal(obj: any) {
        sessionStorage.setItem("dadosCon", JSON.stringify(obj));
        setQualGrid(<><FormTipoLocal voltar={BtnVoltartipoLocal} /></>)
    }

    function tipoLocalDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletartipoLocal(obj.lty_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }



    async function deletartipoLocal(id: any) {

        setOpenModal(false)

        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("localtype/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastrotipoLocal}
                    Iconeditar={EditartipoLocal}
                    iconeExcluir={tipoLocalDeletar}
                    PegarValorGrid={PegarValorGridtipoLocal}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='localtype/buscarlocaltype'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    pag="ok"
                /></>))
                GetLocaltype();

            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastrotipoLocal}
                Iconeditar={EditartipoLocal}
                iconeExcluir={tipoLocalDeletar}
                PegarValorGrid={PegarValorGridtipoLocal}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='localtype/buscarlocaltype'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                pag="ok"
            /></>))
        }
    }

    function BtnVoltartipoLocal() {

        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastrotipoLocal}
            Iconeditar={EditartipoLocal}
            iconeExcluir={tipoLocalDeletar}
            PegarValorGrid={PegarValorGridtipoLocal}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='localtype/buscarlocaltype'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            pag="ok"
        /></>))
        GetLocaltype();
    }

    function PegarValorGridtipoLocal(valor: any) {

        setLty_id(String(valor.lty_id))
        setOpenModalIncluirNovo(false)

    }



    //-------------------------------------------------ADD CONTRATO

    function incluirContrato2() {

        setTitulo('Cadastrar Contrato')
        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastroContrato}
            Iconeditar={EditarContrato}
            iconeExcluir={ContratoDeletar}
            PegarValorGrid={PegarValorGridContrato}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='contract/buscarcontract'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            pag="ok"
        /></>))

        setOpenModalIncluirNovo(true)

    }


    function ChamarCadastroContrato() {
        sessionStorage.removeItem("dadosCon");
        setQualGrid(<><FormContrato voltar={BtnVoltarContrato} /></>)
    }

    async function EditarContrato(obj: any) {
        sessionStorage.setItem("dadosCon", JSON.stringify(obj));
        setQualGrid(<><FormContrato voltar={BtnVoltarContrato} /></>)
    }

    function ContratoDeletar(obj: any) {




        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarDeletar(obj.ctc_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    async function deletarDeletar(id: any) {

        setOpenModal(false)

        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("contract/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastroContrato}
                    Iconeditar={EditarContrato}
                    iconeExcluir={ContratoDeletar}
                    PegarValorGrid={PegarValorGridContrato}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='contract/buscarcontract'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    pag="ok"
                /></>))
                GetContrato();

            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastroContrato}
                Iconeditar={EditarContrato}
                iconeExcluir={ContratoDeletar}
                PegarValorGrid={PegarValorGridContrato}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='contract/buscarcontract'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                pag="ok"
            /></>))
        }
    }

    function BtnVoltarContrato() {

        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastroContrato}
            Iconeditar={EditarContrato}
            iconeExcluir={ContratoDeletar}
            PegarValorGrid={PegarValorGridContrato}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='contract/buscarcontract'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            pag="ok"
        /></>))
        GetContrato()
    }

    function PegarValorGridContrato(valor: any) {

        IncluirContrato(String(valor.ctc_id))
        setOpenModalIncluirNovo(false)

    }

    //-------------------------------------------------ADD CONTRATO
    //=================================ADD UNIDADE=============================================  

    function incluirUnidade() {


        if (ctc_id === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {


            setTitulo('Cadastrar Unidade')
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastroUnidade}
                Iconeditar={EditarUnidade}
                iconeExcluir={UnidadeDeletar}
                PegarValorGrid={PegarValorGridUnidade}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='unity/buscarunity'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={ctc_id.split('_')[0]}
                pag="ok"
            /></>))

            setOpenModalIncluirNovo(true)
        }

    }


    function PegarValorGridUnidade(valor: any) {

        IncluirUnity(String(valor.unt_id))
        setOpenModalIncluirNovo(false)

    }

    function IncluirUnity(Unidade: any) {

        if (Unidade === '') {
            setUnt_id('')
        } else {
            setUnt_id(Unidade)
        }
    }

    function ChamarCadastroUnidade() {
        sessionStorage.removeItem("dadosUni");

        setQualGrid(<><FormUnidade contract={ctc_id} voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
    }

    async function EditarUnidade(obj: any) {
        sessionStorage.setItem("dadosUni", JSON.stringify(obj));
        setQualGrid(<><FormUnidade contract={ctc_id} voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
    }

    function ChamarCadastroContratoUni() {

    }

    function BtnVoltarUnidade() {
        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastroUnidade}
            Iconeditar={EditarUnidade}
            iconeExcluir={UnidadeDeletar}
            PegarValorGrid={PegarValorGridUnidade}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='unity/buscarunity'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            params={ctc_id.split('_')[0]}
            pag="ok"
        /></>))

        GetUnidadeSel(ctc_id.split('_')[0]);
    }

    function UnidadeDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => DeletarUnidade(obj.unt_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }

    async function DeletarUnidade(id: any) {

        GetUnidadeSel(ctc_id.split('_')[0]);
        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("unity/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {
                GetUnidadeSel(ctc_id.split('_')[0]);
                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastroUnidade}
                    Iconeditar={EditarUnidade}
                    iconeExcluir={UnidadeDeletar}
                    PegarValorGrid={PegarValorGridUnidade}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='unity/buscarunity'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    params={ctc_id.split('_')[0]}
                    pag="ok"
                /></>))

                GetUnidadeSel(ctc_id.split('_')[0]);
            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastroUnidade}
                Iconeditar={EditarUnidade}
                iconeExcluir={UnidadeDeletar}
                PegarValorGrid={PegarValorGridUnidade}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='unity/buscarunity'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={ctc_id.split('_')[0]}
                pag="ok"
            /></>))

            GetUnidadeSel(ctc_id.split('_')[0]);
        }
    }

    //=================================ADD UNIDADE=============================================  

    async function confirmarLocal() {

        let ret: AxiosResponse;
        let valor: any;

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)


        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {


                const FiltrarNome = optionsLocal.filter(
                    (e: any) => {
                        return e.loc_alternativeidentifier.toLowerCase() === loc_alternativeidentifier.toLowerCase()
                    }
                );

                const FiltrarDescricao = optionsLocal.filter(
                    (e: any) => {
                        return e.loc_description.toLowerCase() === loc_description.toLowerCase()
                    }
                );

             

                if (loc_description === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)


                } else if (loc_pavement === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o pavimento.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else if (loc_alternativeidentifier === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else if (FiltrarDescricao.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">A descrição já está em uso.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)


                } 
                
                else if (FiltrarNome.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">O identificador alternativo já está em uso.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)


                } else if (ctc_id === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                } else if (unt_id === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione a unidade.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else {
                    let ret: AxiosResponse;

                    const dados = [{
                        loc_active: loc_active,
                        loc_description: loc_description,
                        loc_alternativeidentifier: loc_alternativeidentifier,
                        loc_pavement: loc_pavement,
                        unt_id: unt_id,
                        ctc_id: ctc_id,
                        lty_id: lty_id || null,
                        age_id_insert: secureLocalStorage.getItem('iag'),
                        loc_phoneddd: loc_phoneddd || null,
                        loc_phoneddi: loc_phoneddi || null,
                        loc_phone: loc_phone || null,
                        loc_mobilephoneddd: loc_mobilephoneddd || null,
                        loc_mobilephoneddi: loc_mobilephoneddi || null,
                        loc_mobilephone: loc_mobilephone || null,
                        loc_email: loc_email,
                        loc_observation: loc_observation,
                    }];

                    try {
                        ret = await api.post("local/insert", {
                            data: dados,
                        });
                        if (ret.data.status === 200) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                                <div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso!</span></div>
                                <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                            </div>)
                        }
                    } catch (e: any) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)


                    }
                }

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }


    }

    function finalizarModal1() {
        navigate('/local');
    }


    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>



                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Informações Gerais</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="class-checkbox">
                            <input type="checkbox" id="checkbox-active" checked={loc_active} onChange={ValitarActive} disabled={loc_activeDisabled} />
                            <h4 className="lab-ativo">Ativo</h4>
                        </div>

                        <div className="container-cad-prod19">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Descrição *</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setLoc_description(e.target.value)}
                                        value={loc_description}
                                        autoComplete="off"
                                        disabled={loc_descriptionDisabled}
                                    />

                                </div>
                            </div>

                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Pavimento *</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setLoc_pavement(e.target.value)}
                                        value={loc_pavement}
                                        autoComplete="off"
                                        disabled={loc_pavementDisabled}
                                    />
                                </div>
                            </div>

                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Identificador Alternativo *</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setLoc_alternativeidentifier(e.target.value)}
                                        value={loc_alternativeidentifier}
                                        autoComplete="off"
                                        disabled={loc_alternativeidentifierDisabled}
                                    />
                                </div>
                            </div>

                        </div>


                        <div className="container-cad-prod19">

                            <div id="content1">
                                <div className="select-box-ativos-select">

                                    <div className='details-flex'>
                                        <span className="details">Tipo de Local</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirtipoLocal}>Incluir Novo</span>
                                    </div>



                                    <Select
                                        maxMenuHeight={140}
                                        placeholder={'Selecione...'}
                                        styles={customStyles}
                                        isDisabled={lty_idDisabled}
                                        options={optionTipoLocal}
                                        value={optionTipoLocal.filter(function (option: any) {
                                            return option.value === lty_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setLty_id("") : setLty_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div id="content2">
                                <div className="select-box-ativos-select">

                                    <div className='details-flex'>
                                        <span className="details">Contrato *</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirContrato2}>Incluir Novo</span>
                                    </div>

                                    <Select
                                        maxMenuHeight={140}
                                        placeholder={'Selecione...'}
                                        options={optionContract}
                                        styles={customStyles}
                                        isDisabled={ctc_idDisabled}
                                        value={optionContract.filter(function (option: any) {
                                            return option.value === ctc_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? IncluirContrato("") : IncluirContrato(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div id="content3">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Unidade *</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirUnidade}>Incluir Novo</span>
                                    </div>

                                    <Select
                                        maxMenuHeight={140}
                                        placeholder={'Selecione...'}
                                        options={optionUnity}
                                        styles={customStyles}
                                        isDisabled={unt_idDisabled}
                                        value={optionUnity.filter(function (option: any) {
                                            return option.value === unt_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setUnt_id("") : setUnt_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="container-cad-prod10">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">
                                        E-mail</span>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        onChange={(e) => setLoc_email(e.target.value)}
                                        value={loc_email}
                                        disabled={mailDisabled}
                                    />
                                </div>
                            </div>

                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">DDI</span>
                                    <input
                                        autoComplete="off"
                                        maxLength={3}
                                        type="text"
                                        onChange={(e) => setLoc_mobilephoneddi(maskOnlyNumbers(e.target.value))}
                                        value={loc_mobilephoneddi}
                                        disabled={mobilePhoneddiDisabled}
                                    />
                                </div>
                            </div>


                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">DDD</span>
                                    <input
                                        autoComplete="off"
                                        maxLength={3}
                                        type="text"
                                        onChange={(e) => setLoc_mobilephoneddd(maskOnlyNumbers(e.target.value))}
                                        value={loc_mobilephoneddd}
                                        disabled={mobilePhonedddDisabled}
                                    />
                                </div>
                            </div>

                            <div id="content4">
                                <div className="input-box-ativos">
                                    <span className="details">Celular</span>
                                    <input
                                        autoComplete="off"
                                        maxLength={18}
                                        type="text"
                                        onChange={(e) => setLoc_mobilephone(maskOnlyNumbers(e.target.value))}
                                        value={loc_mobilephone}
                                        disabled={mobilePhoneDisabled}
                                    />
                                </div>
                            </div>

                            <div id="content5">
                                <div className="input-box-ativos">
                                    <span className="details">DDI</span>
                                    <input
                                        autoComplete="off"
                                        maxLength={3}
                                        type="text"
                                        onChange={(e) => setLoc_phoneddi(maskOnlyNumbers(e.target.value))}
                                        value={loc_phoneddi}
                                        disabled={phoneddiDisabled}
                                    />
                                </div>
                            </div>

                            <div id="content6">
                                <div className="input-box-ativos">
                                    <span className="details">DDD</span>
                                    <input
                                        autoComplete="off"
                                        maxLength={3}
                                        type="text"
                                        onChange={(e) => setLoc_phoneddd(maskOnlyNumbers(e.target.value))}
                                        value={loc_phoneddd}
                                        disabled={phonedddDisabled}
                                    />
                                </div>
                            </div>

                            <div id="content7">
                                <div className="input-box-ativos">
                                    <span className="details">Telefone</span>
                                    <input
                                        autoComplete="off"
                                        maxLength={18}
                                        type="text"
                                        onChange={(e) => setLoc_phone(maskOnlyNumbers(e.target.value))}
                                        value={loc_phone}
                                        disabled={phoneDisabled}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="container-cad-prod2">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Data da inclusão</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        value={datetimeinsert}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Data da última atualização</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        value={datetimeupdate}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="container-cad-prod3">
                            <div className="textareaBox-ativo">
                                <span className="details">Observação</span>
                                <textarea
                                    onChange={(e) => setLoc_observation(e.target.value)}
                                    value={loc_observation}
                                    disabled={observationDisabled}
                                />
                            </div>
                        </div>



                        <div className="Bt-button-cad">
                            <button type="submit" disabled={confirmarUserDisabled} onClick={confirmarLocal}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                            </button>
                            <button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
                                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                            </button>
                        </div>

                    </div>
                </main>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <div className='title-modal-incluir'>
                    <h1 className="modal-IncluirNovo-title">{titulo}</h1>
                    <div onClick={fecharOpenModalIncluirNovo}>
                        <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
                <div className='scrol-modal'>

                    {qualGrid}

                </div>
            </ModalIncluirNovo>


            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>



        </>
    );

}

export default LocalAdd;